import { default as indexKkIpCmEOjiMeta } from "/vercel/path0/pages/form/[slug]/index.vue?macro=true";
import { default as responseAA8qIEnqZOMeta } from "/vercel/path0/pages/form/[slug]/response.vue?macro=true";
import { default as createIoGldxo78HMeta } from "/vercel/path0/pages/form/create.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: "form-slug",
    path: "/form/:slug()",
    component: () => import("/vercel/path0/pages/form/[slug]/index.vue")
  },
  {
    name: "form-slug-response",
    path: "/form/:slug()/response",
    component: () => import("/vercel/path0/pages/form/[slug]/response.vue")
  },
  {
    name: "form-create",
    path: "/form/create",
    component: () => import("/vercel/path0/pages/form/create.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  }
]