import {
  faAngleDown as freeFasFaAngleDown,
  faAngleUp as freeFasFaAngleUp,
  faArrowLeft as freeFasFaArrowLeft,
  faAngleRight as freeFasFaAngleRight,
  faCalendar as freeFasFaCalendar,
  faClock as freeFasFaClock,
  faEllipsisVertical as freeFasFaEllipsisVertical,
  faList as freeFasFaList,
  faMagnifyingGlass as freeFasFaMagnifyingGlass,
  faPlus as freeFasFaPlus,
  faRedo as freeFasFaRedo,
  faStar as freeFasFaStar,
  faTriangleExclamation as freeFasFaTriangleExclamation,
  faUndo as freeFasFaUndo,
  faUserGroup as freeFasFaUserGroup,
  faTable as freeFasFaTable,
  faCheckCircle as freeFasFaCheckCircle
} from '@fortawesome/free-solid-svg-icons'

import {
  faArrowProgress as proFarFaArrowProgress,
  faBallotCheck as proFarFaBallotCheck,
  faCalendarDay as proFarFaCalendarDay,
  faEdit as proFarFaEdit,
  faEye as proFarFaEye,
  faGear as proFarFaGear,
  faHammer as proFarFaHammer,
  faIdCard as proFarFaIdCard,
  faLink as proFarFaLink,
  faMicroscope as proFarFaMicroscope,
  faPaperPlaneTop as proFarFaPaperPlaneTop,
  faSend as proFarFaSend,
  faUpload as proFarFaUpload,
  faUsersViewfinder as proFarFaUsersViewfinder,
  faChartScatterBubble as proFarFaChartScatterBubble
} from '@fortawesome/pro-regular-svg-icons'

import {
  faAddressCard as proFadFaAddressCard,
  faBallotCheck as proFadFaBallotCheck,
  faIdCard as proFadFaIdCard,
  faMessageQuestion as proFadFaMessageQuestion,
  faPeopleGroup as proFadFaPeopleGroup
} from '@fortawesome/pro-duotone-svg-icons'

export default {freeFasFaAngleDown, freeFasFaAngleUp, freeFasFaArrowLeft, freeFasFaAngleRight, freeFasFaCalendar, freeFasFaClock, freeFasFaEllipsisVertical, freeFasFaList, freeFasFaMagnifyingGlass, freeFasFaPlus, freeFasFaRedo, freeFasFaStar, freeFasFaTriangleExclamation, freeFasFaUndo, freeFasFaUserGroup, freeFasFaTable, freeFasFaCheckCircle, proFarFaArrowProgress, proFarFaBallotCheck, proFarFaCalendarDay, proFarFaEdit, proFarFaEye, proFarFaGear, proFarFaHammer, proFarFaIdCard, proFarFaLink, proFarFaMicroscope, proFarFaPaperPlaneTop, proFarFaSend, proFarFaUpload, proFarFaUsersViewfinder, proFarFaChartScatterBubble, proFadFaAddressCard, proFadFaBallotCheck, proFadFaIdCard, proFadFaMessageQuestion, proFadFaPeopleGroup}