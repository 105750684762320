import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_adVF2uRka6 from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import fontawesome_7xXmPH2btU from "/vercel/path0/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import surveyjs_client_Nt7XL8uMsx from "/vercel/path0/plugins/surveyjs.client.ts";
import tinymce_client_HIzuAQzspE from "/vercel/path0/plugins/tinymce.client.ts";
import directives_8CcCirWtnE from "/vercel/path0/plugins/directives.ts";
import toast_client_9z8IIRO0Dx from "/vercel/path0/plugins/toast.client.ts";
import vue_final_modal_pML93k5qcp from "/vercel/path0/plugins/vue-final-modal.ts";
import license_client_6OJhw9ce1z from "/vercel/path0/plugins/license.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_adVF2uRka6,
  fontawesome_7xXmPH2btU,
  surveyjs_client_Nt7XL8uMsx,
  tinymce_client_HIzuAQzspE,
  directives_8CcCirWtnE,
  toast_client_9z8IIRO0Dx,
  vue_final_modal_pML93k5qcp,
  license_client_6OJhw9ce1z
]