export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('click-outside', {
        mounted(el, binding) {
            const handler = (e: any) => {
                if (!el.contains(e.target) && el !== e.target) {
                    binding.value(e);
                }
            };
            el.__ClickOutsideHandler__ = handler;
            document.addEventListener('click', handler);
        },
        beforeUnmount(el) {
            document.removeEventListener('click', el.__ClickOutsideHandler__);
        },
        getSSRProps (binding, vnode) {
            return {}
        }
    })
})
